import Button from "components/LSRevamp/common/Button"
import { trackClick } from "scripts/segment"
import { useRouter } from "next/router"
import { navRoutes } from "page_routes"
import { logEvent } from "ga"
import { counsellingStageRank } from "DataMapper/Profile/StageMapper"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { AuthToken } from "lib/auth_token"
import ReactPlayer from "react-player"
import Image from "next/image"

const VideoTestimonials = () => {
  const [heroCtaText, setHeroCtaText] = useState("Start your journey")
  const router = useRouter()
  const authValue = useSelector((state: any) => state?.auth?.auth?.token)
  const authToken = new AuthToken(authValue)
  const isLoggedIn = authToken && authToken.isValid
  const counsellingStage = useSelector(
    (state: any) => state.spotCounselling.stage,
  )

  useEffect(() => {
    if (isLoggedIn) {
      if (counsellingStageRank[counsellingStage as string] <= 2) {
        setHeroCtaText("Start your journey")
      } else {
        setHeroCtaText("Get Access")
      }
    } else {
      setHeroCtaText("Start your journey")
    }
  }, [isLoggedIn, counsellingStage])

  const handleClick = () => {
    trackClick("", {
      widgetName: "Testimonials",
      widgetFormat: "Banner",
      contentName: "Start your Journey",
      contentFormat: "Button",
    })
    if (isLoggedIn) {
      if (counsellingStageRank[counsellingStage as string] <= 2) {
        logEvent("Get Expert Counselling", router.asPath, "Banner")
        router.push(navRoutes.WEBFLOW_PROGRESSIVE_FORM)
      } else {
        logEvent("Get Access", router.asPath, "Banner")
        router.push(navRoutes.POSTLOGIN_COUNSELLING)
      }
    } else {
      router.push(navRoutes.WEBFLOW_PROGRESSIVE_FORM)
    }
  }

  const CustomIcon = () => {
    return (
      <div className="absolute flex justify-center items-center top-[45%] left-[50%] cursor-pointer bg-[#174bd2] px-4 py-2 opacity-70">
        <Image
          src= {`${process.env.CDN_URL}/assets/images/play-icon.png`}
          width={16}
          height={16}
          className="opacity-100"
        />
      </div>
    )
  }

  const videoLinks = [
    {
      url: "https://leap-public.s3.ap-south-1.amazonaws.com/web/assets/videos/LS+Airport+Testimionial+Shravni+ver2.mp4",
      lightSrc:
        "https://leap-public.s3.ap-south-1.amazonaws.com/web/assets/images/sravani-video-testimonials.jpg",
    },
    {
      url: "https://leap-public.s3.ap-south-1.amazonaws.com/web/assets/videos/LS+Testimonial+-+Maisha+Kolkatta+ver2.mp4",
      lightSrc:
        "https://leap-public.s3.ap-south-1.amazonaws.com/web/assets/images/maisha-video-testimonials.jpg",
    },

    {
      url: "https://leap-public.s3.ap-south-1.amazonaws.com/web/assets/videos/LS+Airport+Testimonial+2+Shivanshu-.mp4",
      lightSrc:
        "https://leap-public.s3.ap-south-1.amazonaws.com/web/assets/images/sharma-video-testimonials.jpg",
    },
    {
      url: "https://leap-public.s3.ap-south-1.amazonaws.com/web/assets/videos/LS+Riya+Testimonial+Vertical+adaptation.mp4",
      lightSrc:
        "https://leap-public.s3.ap-south-1.amazonaws.com/web/assets/images/riya-video-testimonials-one.jpg",
    },
  ]

  return (
    <>
      <div className=" mb-8 md:max-w-[1200px] mx-auto">
        <h2 className="font-bold text-2xl text-center text-[#1C1C1C] md:text-4xl mb-[18px] md:mb-[38px] px-4 mt-[55px]">
          More than 2 lac students trust us with their study abroad dream.
          Here's why!
        </h2>
        <div className="flex overflow-x-scroll hide-scroll-bar md:items-center md:justify-center">
          <div className="flex gap-4 md:justify-center overflow-x-auto mb-6 md:max-w-[1200px] mx-4 md:mx-auto">
            {videoLinks?.map((link: any) => {
              const { url, lightSrc } = link
              return (
                <div className="relative min-w-[250px] w-[250px] md:w-[275px]">
                  <ReactPlayer
                    url={url}
                    playing
                    width="100%"
                    height="490px"
                    controls
                    light={
                      <Image
                        height={490}
                        layout="fill"
                        src={lightSrc}
                        alt="Thumbnail"
                      />
                    }
                    playIcon={<CustomIcon />}
                  />
                </div>
              )
            })}
          </div>
        </div>

        <div className="mt-[10px] md:mt-[30px] font-bold mx-auto w-fit">
          <Button
            text={heroCtaText}
            color="text-white"
            backgroundColor="bg-yellow-to-orange"
            handleSpotCounsellingFlow={handleClick}
            width="w-[220px]"
            border="border-0"
          />
        </div>
      </div>
    </>
  )
}

export default VideoTestimonials
